<template>
    <b-row>
        <b-col cols="1">
            <zw-input-group v-model="inputVal.age_from"
                            name="age_from"
                            type="number"
                            disable-label
                            :validate="{ required: true }"
            ></zw-input-group>
        </b-col>
        <b-col cols="1">
            <zw-input-group v-model="inputVal.age_to"
                            name="age_to"
                            type="number"
                            disable-label
                            :validate="{ required: true }"
            ></zw-input-group>
        </b-col>
        <b-col cols="2">
            <zw-select-group v-model="inputVal.type"
                             name="type"
                             :options="[{value:'percent',text:'Percent'},{value:'price',text:'Price'}]"
                             disable-label
                             :validate="{ required: true }"
            ></zw-select-group>
        </b-col>
        <b-col cols="1">
            <zw-input-group v-model="inputVal.value"
                            name="value"
                            disable-label
                            :validate="{ required: true }"
            ></zw-input-group>
        </b-col>
        <b-col cols="2">
            <b-button variant="danger" @click="remove()" :title="$t('common.title.delete')">
                <font-awesome-icon icon="trash"/>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'ChildDiscountLine',
    props: {
        value: {
            type: Object,
            required: true
        },
        remove: {}
    },
    data() {
        return {
            labelPrefix: 'article.label.age_discounts.',
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>